.LVTextWrapper {
  position: absolute;
  right: 5.5%;
  top: 40%;
  height: 800px;
  width: 350px;
  text-align: right;
  color: rgb(255, 32, 32);
}

.lv1 {
  position: absolute;
  padding: 5% 0 0 5%;
  height: 90%;
}

.lv2 {
  position: absolute;
  height: 75%;
  right: 5%;
  top: 134%;
}

.lv3 {
  position: absolute;
  height: 75%;
  left: 5.5%;
  top: 226%;
}

.lv4 {
  position: absolute;
  height: 75%;
  right: 5%;
  top: 319%;
}

.lv5 {
  position: absolute;
  height: 75%;
  left: 5.5%;
  top: 411%;
}

.lv6 {
  position: absolute;
  height: 75%;
  right: 5%;
  top: 503%;
}

.lv7 {
  position: absolute;
  height: 75%;
  left: 5.5%;
  top: 595%;
}

.lv8 {
  position: absolute;
  height: 75%;
  right: 5%;
  top: 687%;
}

.lv9 {
  position: absolute;
  height: 75%;
  left: 5.5%;
  top: 779%;
}

.lv10and11 {
  position: absolute;
  height: 75%;
  right: 5%;
  top: 871%;

}

.lv10 {
  position: relative;
  height: 80%;
  padding-right: 30px;
  padding-bottom: 50px;
}

.lv11 {
  position: relative;
  height: 100%;
}

.lv12 {
  position: absolute;
  height: 75%;
  left: 5.5%;
  top: 963%;
}

.lv13 {
  position: absolute;
  height: 75%;
  right: 5%;
  top: 1056%;
}

.lv14 {
  position: absolute;
  height: 75%;
  left: 5.5%;
  top: 1149%;
}

.lv15 {
  position: absolute;
  height: 75%;
  right: 5%;
  top: 1241%;
}

.lv16 {
  position: absolute;
  height: 75%;
  left: 5.5%;
  top: 1333%;
}

.lv17 {
  position: absolute;
  height: 75%;
  right: 5%;
  top: 1425%;
}

.lv18 {
  position: absolute;
  height: 75%;
  left: 5.5%;
  top: 1517%;
}

.lv19 {
  position: absolute;
  height: 95%;
  right: 5%;
  top: 1609%;
  padding-bottom: 100px;
}

@media screen and (max-width: 900px) and (min-width: 763px) {
  .LVTextWrapper {
    top: 83%;
  }

  .lv1 {
    right: 9%;
    height: 70%;
    padding: 78px 0 0 0;
  }

  .lv2 {
    height: 35%;
    top: 116%;
  }


  .lv3 {
    height: 35%;
    top: 160%;
  }

  .lv4 {
    height: 35%;
    top: 204%;
  }

  .lv5 {
    height: 35%;
    top: 247%;
  }

  .lv6 {
    height: 35%;
    top: 291%;
  }

  .lv7 {
    height: 35%;
    top: 334%;
  }

  .lv8 {
    height: 35%;
    top: 378%;
  }

  .lv9 {
    height: 35%;
    top: 422%;
  }

  .lv10and11 {

    height: 95%;

      top: 466%;

  }

  .lv10 {
    height: 26%;
    padding-right: 20px;
  }

  .lv11 {
    height: 35%;

  }

  .lv12 {
    height: 35%;
    top: 509%;
  }

  .lv13 {
    height: 35%;
    top: 553%;
  }

  .lv14 {
    height: 35%;
    top: 597%;
  }

  .lv15 {
    height: 35%;
    top: 641%;
  }

  .lv16 {
    height: 35%;
    top: 684%;
  }

  .lv17 {
    height: 35%;
    top: 727%;
  }

  .lv18 {
    height: 35%;
    top: 770%;
  }

  .lv19 {
    height: 70%;
    top: 813%;
  }
}

@media screen and (max-width: 600px) and (min-width: 321px) {
  .LVTextWrapper {
    top: 61%;
    width: 200px;
  }

  .lv1 {
    height: 50%;
    padding: 45px 0 0 0;
    right: 9%;
  }

  .lv2 {
    height: 25%;
    top: 126%;
  }

  .lv3 {
    height: 25%;
    top: 158%;
  }

  .lv4 {
    height: 25%;
    top: 190%;
  }

  .lv5 {
    height: 25%;
    top: 222%;
  }

  .lv6 {
    height: 25%;
    top: 254%;
  }

  .lv7 {
    height: 25%;
    top: 286%;
  }

  .lv8 {
    height: 25%;
    top: 318%;
  }

  .lv9 {
    height: 25%;
    top: 350%;
  }
.lv10and11 {
    top: 384.5%;
    height: 24%;

}
  .lv10 {
    height: 85%;
    padding-bottom: 10px;
    padding-right: 10px;
  }

  .lv11 {
    height: 95%;

  }

  .lv12 {
    height: 25%;
    top: 414%;
  }

  .lv13 {
    height: 25%;
    top: 446%;
  }

  .lv14 {
    height: 25%;
    top: 478%;
  }

  .lv15 {
    height: 25%;
    top: 510%;
  }

  .lv16 {
    height: 25%;
    top: 542%;
  }

  .lv17 {
    height: 25%;
    top: 574%;
  }

  .lv18 {
    height: 25%;
    top: 606%;
  }

  .lv19 {
    height: 50%;
    top: 638%;
  }
}

@media screen and (max-width: 320px) {
  .LVTextWrapper {
    top: 61%;
    width: 200px;
  }

  .lv1 {
    height: 50%;
    padding: 45px 0 0 0;
    right: 9%;
  }

  .lv2 {
    height: 25%;
    top: 141%;
  }

  .lv3 {
    height: 25%;
    top: 173%;
  }

  .lv4 {
    height: 25%;
    top: 205%;
  }

  .lv5 {
    height: 25%;
    top: 237%;
  }

  .lv6 {
    height: 25%;
    top: 269%;
  }

  .lv7 {
    height: 25%;
    top: 301%;
  }

  .lv8 {
    height: 25%;
    top: 333%;
  }

  .lv9 {
    height: 25%;
    top: 365%;
  }
  ,
  .lv10 {
    height: 20%;
    top: 399.5%;
  }

  .lv11 {
    height: 25%;
    top: 397%;
  }

  .lv12 {
    height: 25%;
    top: 429%;
  }

  .lv13 {
    height: 25%;
    top: 461%;
  }

  .lv14 {
    height: 25%;
    top: 493%;
  }

  .lv15 {
    height: 25%;
    top: 525%;
  }

  .lv16 {
    height: 25%;
    top: 557%;
  }

  .lv17 {
    height: 25%;
    top: 589%;
  }

  .lv18 {
    height: 25%;
    top: 621%;
  }

  .lv19 {
    height: 50%;
    top: 653%;
  }
}
