.rhonda1 {
  position: absolute;
  padding: 5% 0 0 5%;
  height: 115%;
}

.rhonda2and3 {
  position: absolute;
  top: 148%;
  right: 5%;
  height: 70%;
}

.rhonda2 {
  position: relative;
  height: 75%;
  padding-right: 20px;
  padding-bottom: 45px;
  filter: contrast(110%);
}

.rhonda3 {
  position: relative;
  height: 95%;

}

.rhonda4 {
  position: absolute;
  top: 238%;
  padding-left: 5%;
  height: 88%;
}

.rhonda5 {
  position: absolute;
  top: 345%;
  right: 5%;
  height: 65%;
}

.rhonda6 {
  position: absolute;
  top: 425%;
  padding-left: 5%;
  height: 65%;
  padding-bottom: 4%;
}

.RTextWrapper {
	position: absolute;
	right: 5.5%;
	top: 40%;
	height: 800px;
	width: 350px;
	text-align: right;
	color: rgb(237, 40, 52);
}



@media screen and (max-width: 900px) and (min-width: 763px) {
  .rhonda1 {
    padding: 78px 0 0 0;
    right: 9%;
    height: 85%;
  }

  .rhonda2and3 {
    position: absolute;
    top: 148%;
    right: 9%;
    height: 35%;

  }
  .rhonda2 {

    height: 65%;

  }

  .rhonda3 {
    position: relative;
    height: 85%;

  }
  .rhonda4 {
    top: 190%;
    left: 5%;
    height: 37%;
  }

  .rhonda5 {
    position: absolute;
    top: 243%;
    right: 5%;
    height: 35%;
  }

  .rhonda6 {
    position: absolute;
    top: 290%;
    left: 5%;
    height: 35%;
  }

  .RTextWrapper {
  	top: 100%;
}

}


@media only screen and (max-width: 665px) {
  .rhonda1 {
    height: 60%;
    top: 26px;
    right: 9%;
  }

  .rhonda2and3 {
display:none;
  }

.rhonda2mob {
  position: absolute;
  top: 133%;
  height: 55%;
  right: 9%;
}

.rhonda3mob {
  position: absolute;
  top: 203%;
  height: 27%;
  left: 9%;
}


  .rhonda4 {
    top: 249%;
    height: 30%;
    padding-left: 0;
    right: 5%;
  }

  .rhonda5 {
    top: 290%;
    height: 25%;
    left: 5%;
  }

  .rhonda6 {
    top: 327%;
    height: 30%;
    padding-left: 0%;
    right: 5%;
  }

  .RTextWrapper {
		right: 7%;
		top: 72%;
	}

}
