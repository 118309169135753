.mrOwlText {
  position: absolute;
  right: 5.5%;
  top: 45%;
  height: 800px;
  width: 300px;
  text-align: right;
  color: rgb(111, 69, 59);
}

.mrowl1 {
  position: absolute;
  padding: 5% 0 0 5%;
  height: 115%;
}

.mrowl2 {
  position: absolute;
  right: 5%;
  top: 145%;
  height: 70%;
}

.mrowl3 {
  position: absolute;
  left: 5%;
  top: 235%;
  height: 70%;
}

.mrowl4and5 {
  position: absolute;
  height: 65%;
  top: 325%;
  right: 5%;
}

.mrowl4 {
  position: relative;
  height: 70%;
  padding-right: 30px;
  padding-bottom: 50px;
}

.mrowl5 {
  position: relative;
  height: 90%;
}

.mrowl6 {
  position: absolute;
  top: 403%;
  height: 70%;
  left: 9%;

}

@media screen and (max-width: 900px) and (min-width: 763px) {
  .mrOwlText {
    top: 100%;
    width: 280px;
  }
  .mrowl1 {
    padding: 78px 0 0 0;
    right: 9%;
    height: 85%;
  }
  .mrowl2 {


    top: 135%;
    height: 38%;
  }
  .mrowl3 {

    top: 185%;
    height: 40%;
  }
  .mrowl4and5 {

    height: 50%;
    top: 238%;

  }
  .mrowl4 {

    height: 40%;
    padding-right: 10px;
    padding-bottom: 50px;
  }

  .mrowl5 {

    height: 60%;
  }

  .mrowl6 {

    top: 280%;
    height: 40%;
    left: 5%;


  }
}


@media only screen and (max-width: 665px) {

  .mrOwlText {
    top: 68%;
    width: 210px

  }
  .mrowl1 {
    height: 55%;
    top: 26px;
    right: 9%;
  }
  .mrowl2 {


    top: 118%;
    height: 32%;
  }

  .mrowl3 {


    top: 160%;
    height: 37%;
  }
  .mrowl4and5 {

    height: 53%;
    top: 209%;

  }
  .mrowl4 {

    height: 40%;
    padding-right: 10px;
    padding-bottom: 15px;
  }

  .mrowl5 {

    height: 50%;
  }

  .mrowl6 {

    top: 245%;
    height: 30%;
    left: 5%;



  }
}
