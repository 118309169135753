@import url('https://fonts.googleapis.com/css?family=Archivo+Black');
body {
	margin: 0;
	padding: 0;
	background-color: rgb(255, 246, 32);
	font-family: 'Archivo Black', sans serif;
	overflow-x: hidden;
}

p {
	font-size: 1.4rem;
}

li {
	font-size: 1.4rem;
}

.animate-bottom {
	position: relative;
	-webkit-animation-name: animatebottom;
	-webkit-animation-duration: 1s;
	animation-name: animatebottom;
	animation-duration: 1s;
}

@-webkit-keyframes animatebottom {
	from {
		bottom: -100px;
		opacity: 0;
	}
	to {
		bottom: 0px;
		opacity: 1;
	}
}

@keyframes animatebottom {
	from {
		bottom: -100px;
		opacity: 0;
	}
	to {
		bottom: 0;
		opacity: 1;
	}
}

@media screen and (max-width: 900px) and (min-width: 601px) {
	p {
		font-size: 1rem;
	}
}

@media only screen and (max-width: 600px) {
 p {
		font-size: 0.8rem;
	}

	li{
		font-size: 0.8rem;
	}
}




