@import url(https://fonts.googleapis.com/css?family=Archivo+Black);
body {
	margin: 0;
	padding: 0;
	background-color: rgb(255, 246, 32);
	font-family: 'Archivo Black', sans serif;
	overflow-x: hidden;
}

p {
	font-size: 1.4rem;
}

li {
	font-size: 1.4rem;
}

.animate-bottom {
	position: relative;
	-webkit-animation-name: animatebottom;
	-webkit-animation-duration: 1s;
	animation-name: animatebottom;
	animation-duration: 1s;
}

@-webkit-keyframes animatebottom {
	from {
		bottom: -100px;
		opacity: 0;
	}
	to {
		bottom: 0px;
		opacity: 1;
	}
}

@keyframes animatebottom {
	from {
		bottom: -100px;
		opacity: 0;
	}
	to {
		bottom: 0;
		opacity: 1;
	}
}

@media screen and (max-width: 900px) and (min-width: 601px) {
	p {
		font-size: 1rem;
	}
}

@media only screen and (max-width: 600px) {
 p {
		font-size: 0.8rem;
	}

	li{
		font-size: 0.8rem;
	}
}






.titlesTextBox {
	position: absolute;
	top: 210px;
	right: 5.5%;
	height: 400px;
	width: 369px;
	text-align: right;
}

h3 {
	color: rgb(36, 234, 252);
	font-weight: 400;
}

h3:hover {
	color: rgb(255, 71, 153);
}

.homeMobImg {
	position: relative;
	height: 350px;
	width: 100%;
	top: 400px;
	overflow: hidden;
}

.homeIpadImg {
	position: relative;
	top: 500px;
	width: 100%;
	height: 600px;
	overflow: hidden;
}



@media screen and (max-width: 899px) and (min-width: 601px) {
	h3 {
		font-size: 0.9rem;
	}

	.titlesTextBox {
		height: 300px;
		width: 210px;
		top: 110px;
	}
}

@media only screen and (max-width: 600px) {
	.titlesTextBox {
		top: 70px;
		height: 300px;
		width: 220px;
	}
	h3 {
		font-size: 0.8rem;
	}
}

.aboutImg {
  position: absolute;
  height: 110%;
  left: 7%;
  top: 8%;
}

.bio {
  position: absolute;
  top: 40%;
  text-align: right;
  color: rgb(0,204,255);
  width: 300px;
  right: 5.5%;
  font-size: 1.4rem;
}

.cv {
  position: absolute;
  left: 8%;
  top: 135%;
  color: rgb(174, 0, 255);
  list-style-type: circle
}

@media screen and (max-width: 900px) and (min-width: 601px) {
	.bio {
		font-size: 1rem;
    width: 200px;
	}
  .cv li {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 600px) {
  .aboutImg {
    height: 60%;
    left: 1%;
    opacity: 0.9;
    top: 16%;
  }
  .bio {
    font-size: 0.8rem;
    width: 189px;
    top: 33%;
  }
  .cv {
    top: 115%;
    width: 334px;

  }

  .cv li {
    font-size: 0.6rem;
  }

}

.rhonda1 {
  position: absolute;
  padding: 5% 0 0 5%;
  height: 115%;
}

.rhonda2and3 {
  position: absolute;
  top: 148%;
  right: 5%;
  height: 70%;
}

.rhonda2 {
  position: relative;
  height: 75%;
  padding-right: 20px;
  padding-bottom: 45px;
  -webkit-filter: contrast(110%);
          filter: contrast(110%);
}

.rhonda3 {
  position: relative;
  height: 95%;

}

.rhonda4 {
  position: absolute;
  top: 238%;
  padding-left: 5%;
  height: 88%;
}

.rhonda5 {
  position: absolute;
  top: 345%;
  right: 5%;
  height: 65%;
}

.rhonda6 {
  position: absolute;
  top: 425%;
  padding-left: 5%;
  height: 65%;
  padding-bottom: 4%;
}

.RTextWrapper {
	position: absolute;
	right: 5.5%;
	top: 40%;
	height: 800px;
	width: 350px;
	text-align: right;
	color: rgb(237, 40, 52);
}



@media screen and (max-width: 900px) and (min-width: 763px) {
  .rhonda1 {
    padding: 78px 0 0 0;
    right: 9%;
    height: 85%;
  }

  .rhonda2and3 {
    position: absolute;
    top: 148%;
    right: 9%;
    height: 35%;

  }
  .rhonda2 {

    height: 65%;

  }

  .rhonda3 {
    position: relative;
    height: 85%;

  }
  .rhonda4 {
    top: 190%;
    left: 5%;
    height: 37%;
  }

  .rhonda5 {
    position: absolute;
    top: 243%;
    right: 5%;
    height: 35%;
  }

  .rhonda6 {
    position: absolute;
    top: 290%;
    left: 5%;
    height: 35%;
  }

  .RTextWrapper {
  	top: 100%;
}

}


@media only screen and (max-width: 665px) {
  .rhonda1 {
    height: 60%;
    top: 26px;
    right: 9%;
  }

  .rhonda2and3 {
display:none;
  }

.rhonda2mob {
  position: absolute;
  top: 133%;
  height: 55%;
  right: 9%;
}

.rhonda3mob {
  position: absolute;
  top: 203%;
  height: 27%;
  left: 9%;
}


  .rhonda4 {
    top: 249%;
    height: 30%;
    padding-left: 0;
    right: 5%;
  }

  .rhonda5 {
    top: 290%;
    height: 25%;
    left: 5%;
  }

  .rhonda6 {
    top: 327%;
    height: 30%;
    padding-left: 0%;
    right: 5%;
  }

  .RTextWrapper {
		right: 7%;
		top: 72%;
	}

}

.wrapper {
  width: 100vw;
}

.BSTextWrapper {
  position: absolute;
  right: 5.5%;
  top: 40%;
  height: 800px;
  width: 350px;
  text-align: right;
  color: rgb(0, 33, 255);
}

.bs1 {
  position: absolute;
  padding: 5% 0 0 5%;
  height: 100%;
}

.bs2 {
  position: absolute;
  top: 334%;
  height: 75%;
  right: 5.5%;
}

.bs3 {
  position: absolute;
  top: 432%;
  height: 75%;
  left: 5%;
}

.bs4 {
  position: absolute;
  top: 529%;
  height: 75%;
  right: 5.5%;
}

.bs5 {
  position: absolute;
  top: 627%;
  height: 75%;
  left: 5%;
}

.bs6 {
  position: absolute;
  top: 722%;
  height: 75%;
  right: 5.5%;
  padding-bottom: 100px;
}

@media screen and (max-width: 900px) and (min-width: 763px) {
  .bs1 {
    right: 9%;
    height: 60%;
    padding: 78px 0 0 0;
  }


  .BSTextWrapper {
    top: 75%;
  }


  .bs2 {
    height: 36%;
    top: 218%;
  }

  .bs3 {
    height: 36%;
    top: 266%;
  }

  .bs4 {
    height: 36%;
    top: 314%;

  }

  .bs5 {
    height: 36%;
    top: 362%;
  }

  .bs6 {
    height: 36%;
    top: 411%;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 600px) and (min-width: 321px) {
  .bs1 {
    height: 40%;
    padding: 46px 0 0 0;
    right: 9%;
  }


  .BSTextWrapper {
    top: 53%;
  }

  .bs2 {
    height: 30%;
    top: 210%;
  }

  .bs3 {
    height: 30%;
    top: 251%;
  }

  .bs4 {
    height: 30%;
    top: 294%;
  }

  .bs5 {
    height: 30%;
    top: 337%;
  }

  .bs6 {
    height: 30%;
    top: 379%;
  }
}

@media screen and (max-width: 320px) {
  .bs1 {
    height: 40%;
    padding: 46px 0 0 0;
    right: 9%;
  }


  .BSTextWrapper {
    top: 55%;
  }


  .bs2 {

    height: 33%;
    top: 209%;
  }

  .bs3 {
    height: 33%;
    top: 255%;
  }

  .bs4 {
    height: 32%;
    top: 301%;
  }

  .bs5 {
    height: 32%;
    top: 344%;
  }

  .bs6 {
    height: 32%;
    top: 387%;
  }
}

.bb1 {
	position: absolute;
	top: 7%;
	padding-left: 4%;
	height: 100%;
}

.bb2 {
	position: absolute;
	top: 121%;
	right: 5.5%;
	height: 95%;
}

.bb3and4 {
	position: absolute;
	top: 229%;
	left: 9%;
	height: 87%;
}

.bb3 {
	position: relative;
  height: 100%;
}

.bb4 {
	position: relative;
  height: 80%;
  padding-bottom: 70px;
  padding-left: 80px;

}
.bb5and6 {
	position: absolute;
	top: 334%;
	right: 10%;
	height: 93%;
}

.bb5 {
  position: relative;
  height: 100%;
  padding-right: 50px;
}

.bb6 {
  position: relative;
  height: 100%;
}

.bb7 {
	position: absolute;
	top: 445%;
	padding-left: 5%;
	height: 90%;
}

.bb8and9 {
	position: absolute;
	top: 547%;
	height: 95%;
	right: 9%;
}

.bb8 {
  position: relative;
  height: 60%;
  padding-bottom: 100px;
  padding-right: 30px;
}

.bb9 {
  position: relative;
  height: 95%;
}

.bb10 {
  position: absolute;
	top: 650%;
	left: 1%;
	height: 110%;
}

.bb11and12 {
	position: absolute;
	top: 780%;
	right: 9%;
	height: 95%;
}

.bb11 {
  position: relative;
  height: 85%;
  padding-right: 30px;
  padding-bottom: 35px;
}

.bb12 {
  position: relative;
  height: 96%;
}

.BBdescript {
	position: absolute;
	color: rgb(201, 0, 203);
	text-align: right;
	top: 81%;
	right: 5.5%;
}

.mobImgs {
  display: none;
}

@media screen and (max-width: 900px) and (min-width: 763px) {
	.bb1 {
		top: 78px;
		padding-left: 0;
		height: 60%;
		right: 9%;
	}

  .bb2 {
  top: 95%;
  height: 45%;
  }

.bb3and4 {
  top: 152%;
  left: 9%;
  height: 45%;
  }

.bb5and6 {
  top: 209%;
  height: 35%;
  left: 18%;
  }

.bb7 {
  top: 264%;
  padding-left: 0;
  height: 44%;
  right: 5.5%;
  }

.bb8and9 {
  top: 319%;
  height: 43%;
  right: 9%;  
  }

.bb8 {
  padding-bottom: 70px;
    padding-right: 20px;
  }

.bb10 {
  top: 369%;
  right: 7%;
  height: 51%;
  }

.bb11and12 {
  top: 428%;
  height: 45%;
  }

.bb11 {
  padding-bottom: 22px;
    padding-right: 20px;
  }

.BBdescript {
  top: 72%;
  }
  
}

@media only screen and (max-width: 600px) {

  .mobImgs {
  display: block;
  }

	.bb1 {

		top: 50px;
		right: 9%;
		height: 70%;
	}

	.bb2 {
		top: 106%;
		right: 5.5%;
		height: 41%;
	}

  .desktopImgs {
    display: none;
  }

  .bb3mob {
  position: absolute;
  height: 40%;
  left: 6.5%;
  top: 155%;
}


.bb4mob {
  position: absolute;
  height: 50%;
  right: 5.5%;
  top: 210%;
}
.bb5mob {
  position: absolute;
		height: 50%;
		right: 2.5%;
		top: 321%;
	}


	.bb6mob {
  position: absolute;
		height: 43%;
		left: 6.5%;
		top: 268%;
	}

  .bb7 {
  top: 382%;
  height: 30%;
  padding-left: 0;
  left: 4.5%;
}

  .bb8mob {
  position: absolute;
  height: 34%;
  right: 3.5%;
  top: 426%;
}


.bb9mob {
  position: absolute;
  height: 43%;
  left: 6.5%;
  top: 470%;
}

.bb10 {
  top: 520%;
  height: 40%;
  right: 5.5%;
}

.bb11mob {
  position: absolute;
		height: 35%;
		right: 6.5%;
		top: 568%;
	}


	.bb12mob {
  position: absolute;
		height: 45%;
		left: 6.5%;
		top: 613%;
	}

}

.LVTextWrapper {
  position: absolute;
  right: 5.5%;
  top: 40%;
  height: 800px;
  width: 350px;
  text-align: right;
  color: rgb(255, 32, 32);
}

.lv1 {
  position: absolute;
  padding: 5% 0 0 5%;
  height: 90%;
}

.lv2 {
  position: absolute;
  height: 75%;
  right: 5%;
  top: 134%;
}

.lv3 {
  position: absolute;
  height: 75%;
  left: 5.5%;
  top: 226%;
}

.lv4 {
  position: absolute;
  height: 75%;
  right: 5%;
  top: 319%;
}

.lv5 {
  position: absolute;
  height: 75%;
  left: 5.5%;
  top: 411%;
}

.lv6 {
  position: absolute;
  height: 75%;
  right: 5%;
  top: 503%;
}

.lv7 {
  position: absolute;
  height: 75%;
  left: 5.5%;
  top: 595%;
}

.lv8 {
  position: absolute;
  height: 75%;
  right: 5%;
  top: 687%;
}

.lv9 {
  position: absolute;
  height: 75%;
  left: 5.5%;
  top: 779%;
}

.lv10and11 {
  position: absolute;
  height: 75%;
  right: 5%;
  top: 871%;

}

.lv10 {
  position: relative;
  height: 80%;
  padding-right: 30px;
  padding-bottom: 50px;
}

.lv11 {
  position: relative;
  height: 100%;
}

.lv12 {
  position: absolute;
  height: 75%;
  left: 5.5%;
  top: 963%;
}

.lv13 {
  position: absolute;
  height: 75%;
  right: 5%;
  top: 1056%;
}

.lv14 {
  position: absolute;
  height: 75%;
  left: 5.5%;
  top: 1149%;
}

.lv15 {
  position: absolute;
  height: 75%;
  right: 5%;
  top: 1241%;
}

.lv16 {
  position: absolute;
  height: 75%;
  left: 5.5%;
  top: 1333%;
}

.lv17 {
  position: absolute;
  height: 75%;
  right: 5%;
  top: 1425%;
}

.lv18 {
  position: absolute;
  height: 75%;
  left: 5.5%;
  top: 1517%;
}

.lv19 {
  position: absolute;
  height: 95%;
  right: 5%;
  top: 1609%;
  padding-bottom: 100px;
}

@media screen and (max-width: 900px) and (min-width: 763px) {
  .LVTextWrapper {
    top: 83%;
  }

  .lv1 {
    right: 9%;
    height: 70%;
    padding: 78px 0 0 0;
  }

  .lv2 {
    height: 35%;
    top: 116%;
  }


  .lv3 {
    height: 35%;
    top: 160%;
  }

  .lv4 {
    height: 35%;
    top: 204%;
  }

  .lv5 {
    height: 35%;
    top: 247%;
  }

  .lv6 {
    height: 35%;
    top: 291%;
  }

  .lv7 {
    height: 35%;
    top: 334%;
  }

  .lv8 {
    height: 35%;
    top: 378%;
  }

  .lv9 {
    height: 35%;
    top: 422%;
  }

  .lv10and11 {

    height: 95%;

      top: 466%;

  }

  .lv10 {
    height: 26%;
    padding-right: 20px;
  }

  .lv11 {
    height: 35%;

  }

  .lv12 {
    height: 35%;
    top: 509%;
  }

  .lv13 {
    height: 35%;
    top: 553%;
  }

  .lv14 {
    height: 35%;
    top: 597%;
  }

  .lv15 {
    height: 35%;
    top: 641%;
  }

  .lv16 {
    height: 35%;
    top: 684%;
  }

  .lv17 {
    height: 35%;
    top: 727%;
  }

  .lv18 {
    height: 35%;
    top: 770%;
  }

  .lv19 {
    height: 70%;
    top: 813%;
  }
}

@media screen and (max-width: 600px) and (min-width: 321px) {
  .LVTextWrapper {
    top: 61%;
    width: 200px;
  }

  .lv1 {
    height: 50%;
    padding: 45px 0 0 0;
    right: 9%;
  }

  .lv2 {
    height: 25%;
    top: 126%;
  }

  .lv3 {
    height: 25%;
    top: 158%;
  }

  .lv4 {
    height: 25%;
    top: 190%;
  }

  .lv5 {
    height: 25%;
    top: 222%;
  }

  .lv6 {
    height: 25%;
    top: 254%;
  }

  .lv7 {
    height: 25%;
    top: 286%;
  }

  .lv8 {
    height: 25%;
    top: 318%;
  }

  .lv9 {
    height: 25%;
    top: 350%;
  }
.lv10and11 {
    top: 384.5%;
    height: 24%;

}
  .lv10 {
    height: 85%;
    padding-bottom: 10px;
    padding-right: 10px;
  }

  .lv11 {
    height: 95%;

  }

  .lv12 {
    height: 25%;
    top: 414%;
  }

  .lv13 {
    height: 25%;
    top: 446%;
  }

  .lv14 {
    height: 25%;
    top: 478%;
  }

  .lv15 {
    height: 25%;
    top: 510%;
  }

  .lv16 {
    height: 25%;
    top: 542%;
  }

  .lv17 {
    height: 25%;
    top: 574%;
  }

  .lv18 {
    height: 25%;
    top: 606%;
  }

  .lv19 {
    height: 50%;
    top: 638%;
  }
}

@media screen and (max-width: 320px) {
  .LVTextWrapper {
    top: 61%;
    width: 200px;
  }

  .lv1 {
    height: 50%;
    padding: 45px 0 0 0;
    right: 9%;
  }

  .lv2 {
    height: 25%;
    top: 141%;
  }

  .lv3 {
    height: 25%;
    top: 173%;
  }

  .lv4 {
    height: 25%;
    top: 205%;
  }

  .lv5 {
    height: 25%;
    top: 237%;
  }

  .lv6 {
    height: 25%;
    top: 269%;
  }

  .lv7 {
    height: 25%;
    top: 301%;
  }

  .lv8 {
    height: 25%;
    top: 333%;
  }

  .lv9 {
    height: 25%;
    top: 365%;
  }
  ,
  .lv10 {
    height: 20%;
    top: 399.5%;
  }

  .lv11 {
    height: 25%;
    top: 397%;
  }

  .lv12 {
    height: 25%;
    top: 429%;
  }

  .lv13 {
    height: 25%;
    top: 461%;
  }

  .lv14 {
    height: 25%;
    top: 493%;
  }

  .lv15 {
    height: 25%;
    top: 525%;
  }

  .lv16 {
    height: 25%;
    top: 557%;
  }

  .lv17 {
    height: 25%;
    top: 589%;
  }

  .lv18 {
    height: 25%;
    top: 621%;
  }

  .lv19 {
    height: 50%;
    top: 653%;
  }
}

.plum1 {
  position: absolute;
  width: 100%;
  top: 42%;
}

.plum2and3 {
  position: absolute;
  height: 55%;
  top: 152%;
  left: 7%;

}

.plum2 {
  position: relative;
  padding-right: 30px;
  padding-bottom: 37px;
  height: 105%;
}

.plum3 {
  position: relative;

  height: 125%;
}

.plum4 {
  position: absolute;
  height: 80%;
  top: 242%;
  left: 3%;
}

.plum5 {
  position: absolute;
  height: 80%;
  top: 342%;
  right: 5%;
}

.plum6 {
  position: absolute;
  height: 80%;
  top: 440%;
  left: 3%;
  padding-bottom: 3%;
}


@media screen and (max-width: 900px) and (min-width: 763px)  {
  .plum1 {
    top: 130px;
  }

  .plum2and3 {

    height: 55%;
    top: 65%;


  }

  .plum2 {
    position: relative;
    padding-right: 20px;
    padding-bottom: 37px;
    height: 45%;
  }

  .plum3 {
    position: relative;

    height: 55%;
  }

  .plum4 {
    height: 35%;
    top: 107%;
    left: 3%;
  }


  .plum5 {
    height: 40%;
    top: 153%;
    right: 9%;
    height: 40%;
  }

  .plum6 {
    height: 40%;
    top: 205%;
    left: 3%;
  }

}

@media only screen and (max-width: 600px) {
  .plum1 {
    top: 18%;
  }
  .plum2and3{
    display: none;
  }

  .plum2mob {
    position: absolute;
    height: 50%;
    left: 9%;
  top: 60%;
  }
  .plum3mob {
    position: absolute;
    height: 26%;
  right: 5.5%;
  top: 120%;
  }

  .plum4 {
  top: 156%;
height: 26%;
  left: 5.5%;
}

.plum5 {
  top: 192%;

  height: 28%;

}

.plum6 {

  top: 236%;

  height: 28%;
  left: 9%;
}
}

.contactWrapper {
	position: absolute;
	height: 100vh;
	left: 0%;
	overflow: hidden;
}

.contactImg {
	position: relative;
	height: 100%;
}
.tagline {
	position: absolute;
	right: 5.5%;
	top: 35%;
	height: 350px;
	width: 350px;
	text-align: right;
	color: rgb(0, 179, 255);
}
.email {
	position: absolute;
	display: flex;
	color: rgb(0, 179, 255);
}

.twitter {
	height: 22px;
	padding-top: 24px;
	padding-left: 15px;
}

.insta {
	height: 22px;
	padding-top: 24px;
	padding-left: 15px;
}

.contacts {
	position: absolute;
	width: 360px;
	height: 60px;
	top: 75%;
	right: 5.5%;
}

@media screen and (max-width: 900px) and (min-width: 601px) {
  .contactImg {
  right: 59%;
}
.twitter {
  height: 15px;
  padding-top: 20px;
}


.insta {
  height: 15px;
  padding-top: 20px;
}

.contacts {
  width: 270px;
}
}

@media only screen and (max-width: 600px) {
	.contactImg {
		right: 81%;
	}

	.contacts {
		right: 5.5%;
		top: 55%;
		width: 210px;
	}

	.insta {
		height: 12px;
		padding-top: 14px;
		padding-left: 10px;
	}

	.twitter {
		height: 12px;
		padding-top: 14px;
		padding-left: 10px;
	}
}

.mrOwlText {
  position: absolute;
  right: 5.5%;
  top: 45%;
  height: 800px;
  width: 300px;
  text-align: right;
  color: rgb(111, 69, 59);
}

.mrowl1 {
  position: absolute;
  padding: 5% 0 0 5%;
  height: 115%;
}

.mrowl2 {
  position: absolute;
  right: 5%;
  top: 145%;
  height: 70%;
}

.mrowl3 {
  position: absolute;
  left: 5%;
  top: 235%;
  height: 70%;
}

.mrowl4and5 {
  position: absolute;
  height: 65%;
  top: 325%;
  right: 5%;
}

.mrowl4 {
  position: relative;
  height: 70%;
  padding-right: 30px;
  padding-bottom: 50px;
}

.mrowl5 {
  position: relative;
  height: 90%;
}

.mrowl6 {
  position: absolute;
  top: 403%;
  height: 70%;
  left: 9%;

}

@media screen and (max-width: 900px) and (min-width: 763px) {
  .mrOwlText {
    top: 100%;
    width: 280px;
  }
  .mrowl1 {
    padding: 78px 0 0 0;
    right: 9%;
    height: 85%;
  }
  .mrowl2 {


    top: 135%;
    height: 38%;
  }
  .mrowl3 {

    top: 185%;
    height: 40%;
  }
  .mrowl4and5 {

    height: 50%;
    top: 238%;

  }
  .mrowl4 {

    height: 40%;
    padding-right: 10px;
    padding-bottom: 50px;
  }

  .mrowl5 {

    height: 60%;
  }

  .mrowl6 {

    top: 280%;
    height: 40%;
    left: 5%;


  }
}


@media only screen and (max-width: 665px) {

  .mrOwlText {
    top: 68%;
    width: 210px

  }
  .mrowl1 {
    height: 55%;
    top: 26px;
    right: 9%;
  }
  .mrowl2 {


    top: 118%;
    height: 32%;
  }

  .mrowl3 {


    top: 160%;
    height: 37%;
  }
  .mrowl4and5 {

    height: 53%;
    top: 209%;

  }
  .mrowl4 {

    height: 40%;
    padding-right: 10px;
    padding-bottom: 15px;
  }

  .mrowl5 {

    height: 50%;
  }

  .mrowl6 {

    top: 245%;
    height: 30%;
    left: 5%;



  }
}

.akText {
    position: absolute;
	right: 5.5%;
	top: 40%;
	height: 800px;
	width: 290px;
    text-align: right;
    color: rgb(9, 116, 165);
}

.ak1 {
    position: absolute;
    padding: 5% 0 0 5%;
    height: 85%;
  }

.ak2 {
    position: absolute;
    height: 70%;
    top: 110%;
    right: 5%;
  }

  .ak3 {
    position: absolute;
    height: 70%;
    top: 193%;
    left: 5%;
}

.ak4 {
    position: absolute;
    height: 70%;
    top: 277%;
    right: 5%;
}

.ak5and6{
    position: absolute;
    height: 90%;
    width: 85%;
    top: 366%;
    left: 5%;
}

.ak5 {
    position: relative;
    height: 90%;
    
}

.ak6 {
    position: relative;
    padding-left: 5%;
    padding-bottom: 8%;
}

.ak7 {
    position: absolute;
    height: 70%;
    top: 113%;
    right: -5%;
}

.ak8 {
    position: absolute;
    height: 70%;
    top: 203%;
    left: 5%;
}

.ak9 {
    position: absolute;
    height: 70%;
    top: 290%;
    right: -5%;
}

.ak10 {
    position: absolute;
    left: 5%;
    height: 85%;
    top: 380%;
}



@media screen and (max-width: 900px) and (min-width: 763px) {

    .akText {
        top: 75%;
        width: 230px;
    }

    .ak1 {
      padding: 108px 0 0 0;
      right: 9%;
      height: 60%;
    }

    .ak2 {
        top: 100%;
        right: 9%;
        height: 40%;
    }

    .ak3 {
        top: 150%;
        height: 40%;
    }

    .ak4 {
        top: 201%;
        height: 40%;
    }

    .ak5and6{
        height: 40%;
        width: 65%;
        top: 253%;
    }

    .ak6 {
        height: 50%;
        padding-bottom: 15%;
    }

    .ak7 {
        height: 80%;
        right: -30%;
    }

    .ak8 {
        top: 217%;
        height: 80%;
    }

    .ak9 {
        top: 319%;
        right: -30%;
    }

    .ak10 {
        top: 407%;
        height: 90%;
    }
}
.player-wrapper {
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
  }
   
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
@media only screen and (max-width: 665px) {
    .akText {
        top: 60%;
        width: 160px;
    }

    .ak1 {
        padding: 68px 0 0 0;
        right: 9%;
        height: 45%;
    }

    .ak2 {
        top: 95%;
        right: 9%;
        height: 30%;
    }

    .ak3 {
        top: 135%;
        height: 30%;
    }

    .ak4 {
        top: 177%;
        height: 30%;
        right: 9%;
    }

    .ak5and6{
        height: 40%;
        width: 85%;
        top: 219%;
    }

    .ak6 {
        height: 50%;
        padding-bottom: 15%;
    }

    .ak7 {
        padding-top: 10%;
        height: 70%;
        right: 0%;
    }

    .ak8 {
        top: 230%;
        height: 70%;
    }

    .ak9 {
        top: 330%;
        height: 70%;
        right: 0%;
    }

    .ak10 {
        top: 433%;
        height: 80%;
    }

}
.image_wrapper {
    position: realtive;
    width: 100%;
  
  }
  
  .sfish1 {
    position: absolute;
    padding: 5% 0 0 5%;
    height: 115%;

  }
  

.sfish2 {
    position: absolute;
    height: 75%;
    top: 237%;
    left: 5%;

  }

  .sfish3 {
    position: absolute;
    height: 75%;
    top: 330%;
    right: 5%;

  }

  .sfish4 {
    position: absolute;
    height: 75%;
    top: 420%;
    left: 5%;
  }

  .sfish5 {
    position: absolute;
    height: 75%;
    top: 510%;
    right: 5%;
  }

  .sfish6 {
    position: absolute;
    height: 75%;
    top: 600%;
    left: 5%;
  }


  .sfish7 {
    position: absolute;
    height: 75%;
    top: 690%;
    right: 5%;
  }

  .sfish8 {
    position: absolute;
    height: 75%;
    top: 780%;
    left: 5%;
  }

  .sfish10 {
    position: absolute;
    height: 75%;
    top: 870%;
    right: 5%;
  }

  .sfish14 {
    position: absolute;
    height: 75%;
    top: 960%;
    left: 5%;
  }


  .sfish16 {
    position: absolute;
    height: 75%;
    top: 1050%;
    right: 5%;
  }

@media screen and (max-width: 900px) and (min-width: 763px) {
    .sfish1 {
      padding: 78px 0 0 0;
      right: 9%;
      height: 85%;
    }
  

    .sfish2 {
      top: 175%;
      right: 9%;
      height: 37%;
    }
  
    .sfish3 {
 
      top: 220%;
      right: 5%;
      height: 35%;
    }
  
 
    .sfish4 {
     
      height: 35%;
      top: 263%;
      left: 5%;
    }
  
    .sfish5 {
    
      height: 35%;
      top: 306%;
      right: 5%;
    }
  
    .sfish6 {
      
      height: 35%;
      top: 349%;
      left: 5%;
    }
  
  
    .sfish7 {
      height: 35%;
      top: 392%;
      right: 5%;
    }
  
    .sfish8 {
      height: 35%;
      top: 435%;
      left: 5%;
    }
  
    .sfish10 {
      height: 35%;
      top: 478%;
      right: 5%;
    }
  
    .sfish14 {
      height: 35%;
      top: 520%;
      left: 5%;
    }
  
  
    .sfish16 {
      height: 35%;
      top: 563%;
      right: 5%;
    }

  
  }
  
  
  @media only screen and (max-width: 665px) {
    .sfish1 {
      height: 60%;
      top: 26px;
      right: 9%;
    }
  
    .sfish2 {
      top: 143%;
      height: 16%;
      right: 9%;
    }
  
    .sfish3 {
      top: 161%;
      height: 16%;
      right: 5%;
    }
    .sfish4 {
     
      height: 16%;
      top: 182%;
      left: 5%;
    }
  
    .sfish5 {
    
      height: 16%;
      top: 203%;
      right: 5%;
    }
  
    .sfish6 {
      
      height: 16%;
      top: 224%;
      left: 5%;
    }
  
  
    .sfish7 {
      height: 16%;
      top: 245%;
      right: 5%;
    }
  
    .sfish8 {
      height: 16%;
      top: 266%;
      left: 5%;
    }
  
    .sfish10 {
      height: 16%;
      top: 287%;
      right: 5%;
    }
  
    .sfish14 {
      height: 16%;
      top: 308%;
      left: 5%;
    }
  
  
    .sfish16 {
      height: 16%;
      top: 329%;
      right: 5%;
    }

  }
  
