.aboutImg {
  position: absolute;
  height: 110%;
  left: 7%;
  top: 8%;
}

.bio {
  position: absolute;
  top: 40%;
  text-align: right;
  color: rgb(0,204,255);
  width: 300px;
  right: 5.5%;
  font-size: 1.4rem;
}

.cv {
  position: absolute;
  left: 8%;
  top: 135%;
  color: rgb(174, 0, 255);
  list-style-type: circle
}

@media screen and (max-width: 900px) and (min-width: 601px) {
	.bio {
		font-size: 1rem;
    width: 200px;
	}
  .cv li {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 600px) {
  .aboutImg {
    height: 60%;
    left: 1%;
    opacity: 0.9;
    top: 16%;
  }
  .bio {
    font-size: 0.8rem;
    width: 189px;
    top: 33%;
  }
  .cv {
    top: 115%;
    width: 334px;

  }

  .cv li {
    font-size: 0.6rem;
  }

}
