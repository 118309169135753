.wrapper {
  width: 100vw;
}

.BSTextWrapper {
  position: absolute;
  right: 5.5%;
  top: 40%;
  height: 800px;
  width: 350px;
  text-align: right;
  color: rgb(0, 33, 255);
}

.bs1 {
  position: absolute;
  padding: 5% 0 0 5%;
  height: 100%;
}

.bs2 {
  position: absolute;
  top: 334%;
  height: 75%;
  right: 5.5%;
}

.bs3 {
  position: absolute;
  top: 432%;
  height: 75%;
  left: 5%;
}

.bs4 {
  position: absolute;
  top: 529%;
  height: 75%;
  right: 5.5%;
}

.bs5 {
  position: absolute;
  top: 627%;
  height: 75%;
  left: 5%;
}

.bs6 {
  position: absolute;
  top: 722%;
  height: 75%;
  right: 5.5%;
  padding-bottom: 100px;
}

@media screen and (max-width: 900px) and (min-width: 763px) {
  .bs1 {
    right: 9%;
    height: 60%;
    padding: 78px 0 0 0;
  }


  .BSTextWrapper {
    top: 75%;
  }


  .bs2 {
    height: 36%;
    top: 218%;
  }

  .bs3 {
    height: 36%;
    top: 266%;
  }

  .bs4 {
    height: 36%;
    top: 314%;

  }

  .bs5 {
    height: 36%;
    top: 362%;
  }

  .bs6 {
    height: 36%;
    top: 411%;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 600px) and (min-width: 321px) {
  .bs1 {
    height: 40%;
    padding: 46px 0 0 0;
    right: 9%;
  }


  .BSTextWrapper {
    top: 53%;
  }

  .bs2 {
    height: 30%;
    top: 210%;
  }

  .bs3 {
    height: 30%;
    top: 251%;
  }

  .bs4 {
    height: 30%;
    top: 294%;
  }

  .bs5 {
    height: 30%;
    top: 337%;
  }

  .bs6 {
    height: 30%;
    top: 379%;
  }
}

@media screen and (max-width: 320px) {
  .bs1 {
    height: 40%;
    padding: 46px 0 0 0;
    right: 9%;
  }


  .BSTextWrapper {
    top: 55%;
  }


  .bs2 {

    height: 33%;
    top: 209%;
  }

  .bs3 {
    height: 33%;
    top: 255%;
  }

  .bs4 {
    height: 32%;
    top: 301%;
  }

  .bs5 {
    height: 32%;
    top: 344%;
  }

  .bs6 {
    height: 32%;
    top: 387%;
  }
}
