.bb1 {
	position: absolute;
	top: 7%;
	padding-left: 4%;
	height: 100%;
}

.bb2 {
	position: absolute;
	top: 121%;
	right: 5.5%;
	height: 95%;
}

.bb3and4 {
	position: absolute;
	top: 229%;
	left: 9%;
	height: 87%;
}

.bb3 {
	position: relative;
  height: 100%;
}

.bb4 {
	position: relative;
  height: 80%;
  padding-bottom: 70px;
  padding-left: 80px;

}
.bb5and6 {
	position: absolute;
	top: 334%;
	right: 10%;
	height: 93%;
}

.bb5 {
  position: relative;
  height: 100%;
  padding-right: 50px;
}

.bb6 {
  position: relative;
  height: 100%;
}

.bb7 {
	position: absolute;
	top: 445%;
	padding-left: 5%;
	height: 90%;
}

.bb8and9 {
	position: absolute;
	top: 547%;
	height: 95%;
	right: 9%;
}

.bb8 {
  position: relative;
  height: 60%;
  padding-bottom: 100px;
  padding-right: 30px;
}

.bb9 {
  position: relative;
  height: 95%;
}

.bb10 {
  position: absolute;
	top: 650%;
	left: 1%;
	height: 110%;
}

.bb11and12 {
	position: absolute;
	top: 780%;
	right: 9%;
	height: 95%;
}

.bb11 {
  position: relative;
  height: 85%;
  padding-right: 30px;
  padding-bottom: 35px;
}

.bb12 {
  position: relative;
  height: 96%;
}

.BBdescript {
	position: absolute;
	color: rgb(201, 0, 203);
	text-align: right;
	top: 81%;
	right: 5.5%;
}

.mobImgs {
  display: none;
}

@media screen and (max-width: 900px) and (min-width: 763px) {
	.bb1 {
		top: 78px;
		padding-left: 0;
		height: 60%;
		right: 9%;
	}

  .bb2 {
  top: 95%;
  height: 45%;
  }

.bb3and4 {
  top: 152%;
  left: 9%;
  height: 45%;
  }

.bb5and6 {
  top: 209%;
  height: 35%;
  left: 18%;
  }

.bb7 {
  top: 264%;
  padding-left: 0;
  height: 44%;
  right: 5.5%;
  }

.bb8and9 {
  top: 319%;
  height: 43%;
  right: 9%;  
  }

.bb8 {
  padding-bottom: 70px;
    padding-right: 20px;
  }

.bb10 {
  top: 369%;
  right: 7%;
  height: 51%;
  }

.bb11and12 {
  top: 428%;
  height: 45%;
  }

.bb11 {
  padding-bottom: 22px;
    padding-right: 20px;
  }

.BBdescript {
  top: 72%;
  }
  
}

@media only screen and (max-width: 600px) {

  .mobImgs {
  display: block;
  }

	.bb1 {

		top: 50px;
		right: 9%;
		height: 70%;
	}

	.bb2 {
		top: 106%;
		right: 5.5%;
		height: 41%;
	}

  .desktopImgs {
    display: none;
  }

  .bb3mob {
  position: absolute;
  height: 40%;
  left: 6.5%;
  top: 155%;
}


.bb4mob {
  position: absolute;
  height: 50%;
  right: 5.5%;
  top: 210%;
}
.bb5mob {
  position: absolute;
		height: 50%;
		right: 2.5%;
		top: 321%;
	}


	.bb6mob {
  position: absolute;
		height: 43%;
		left: 6.5%;
		top: 268%;
	}

  .bb7 {
  top: 382%;
  height: 30%;
  padding-left: 0;
  left: 4.5%;
}

  .bb8mob {
  position: absolute;
  height: 34%;
  right: 3.5%;
  top: 426%;
}


.bb9mob {
  position: absolute;
  height: 43%;
  left: 6.5%;
  top: 470%;
}

.bb10 {
  top: 520%;
  height: 40%;
  right: 5.5%;
}

.bb11mob {
  position: absolute;
		height: 35%;
		right: 6.5%;
		top: 568%;
	}


	.bb12mob {
  position: absolute;
		height: 45%;
		left: 6.5%;
		top: 613%;
	}

}
